import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import mitt from 'mitt';
import router from './router';

export const emitter = mitt();

const store = createStore({
  state: {
    token: null,
    tokenTimestamp: null,
    email: null,
    completeConfig: false,
    navbarData: null,
    sidebarData: null,
    restaurantId: null,
    menus: [], // Para armazenar os menus
    items: {}, // Para armazenar os itens dos menus
    crossSellItems: [], // Para armazenar os itens de cross-sell
    categories: [], // Para armazenar as categorias gerais
    formCategories: [], // Para armazenar as categorias para o formulário
    formComplements: [], // Para armazenar os complementos para o formulário
    available: true, // Para armazenar se o restaurante está liberado ou não
    registrationInProgress: false, 
  },
  mutations: {
    setToken(state, { token, timestamp }) {
      state.token = token;
      state.tokenTimestamp = timestamp;
    },
    clearToken(state) {
      state.token = null;
      state.tokenTimestamp = null;
    },
    setEmail(state, { email }) {
      state.email = email;
    },
    setCompleteStep(state, { step }) {
      state.sidebarData.links[step].complete = true;
    },
    setCompleteConfig(state, { completeConfig }) {
      state.completeConfig = completeConfig;
    },
    async setNavbarAndSidebarData(state, data) {
      const oldSidebarData = state.sidebarData;
      state.navbarData = {
        notifications: 4,
        open: data.is_open,
        time: data.is_open
          ? (data.opening_time 
              ? `${data.opening_time.split(':')[0]}h às ${data.closing_time === '23:59' ? '23:59' : `${data.closing_time.split(':')[0]}h`}` 
              : "")
          : (data.opening_time ? `${data.opening_time.split(':')[0]}h` : ""),
        preparation: data.is_open ? `${data.status === 1 ? 'Ajustado' : data.status === 2 ? 'Pausado' : 'Padrão'} (${data.total_preparation_time} min)` : '',
        preparationStatus: data.status === 1 ? 'adjusted' : data.status === 2 ? 'paused' : 'default',
        total_preparation_time: data.total_preparation_time
      };
      state.sidebarData = {
        logo: data.logo_url,
        company: data.restaurant_name,
        address: data.address,
        message: null
      };
      if (state.token) {
        const response = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/restaurant/${state.restaurantId}/steps`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
        if (response.data) {
          state.available = response.data.status;
          state.completeConfig = response.data.complete;
          if (!response.data.complete || !response.data.status) {
            state.navbarData['notifications'] = null;
            state.navbarData['opening_time'] = null;
            state.sidebarData['message'] = "Configure os menus abaixo para começar a receber pedidos!";
            state.sidebarData['links'] = {
              store: { complete: response.data.store },
              hours: { complete: response.data.hours },
              preparation: { complete: response.data.preparation },
              profile: { complete: response.data.profile },
              menu: { complete: response.data.menu },
              config: { complete: response.data.config },          
            };
          }
          emitter.emit('navbarDataLoaded');
        }
      }
    },
    setRestaurantId(state, restaurantId) {
      state.restaurantId = restaurantId;
    },
    setMenusAndItems(state, { menus, items, crossSellItems }) {
      state.menus = menus;
      state.items = items;
      state.crossSellItems = crossSellItems;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setFormCategories(state, formCategories) {
      state.formCategories = formCategories;
    },
    setFormComplements(state, formComplements) {
      state.formComplements = formComplements;
    },
    ADD_CATEGORY(state, category) {
      state.formCategories.push(category);
    },
    setRegistrationInProgress(state, inProgress) {
      state.registrationInProgress = inProgress;
    }
  },
  actions: {
    saveToken({ commit }, token) {
      return new Promise((resolve) => {
        const timestamp = new Date().getTime();
        commit('setToken', { token, timestamp });
        resolve(true);
      });
    },
    removeToken({ commit }) {
      commit('clearToken');
    },
    saveEmail({ commit }, email) {
      commit('setEmail', { email });
    },
    async saveCompleteStep({ commit, state }, step) {
      try {
        await axios.post(
          `${process.env.VUE_APP_LARAVEL_API_URL}/restaurant/${state.restaurantId}/step`,
          {
            step: step,
            value: true,
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }
        );
      } catch (error) {
        console.error(`Erro ao salvar etapa "${step}":`, error);
      }
      commit('setCompleteStep', { step });
    },
    async saveCompleteConfig({ commit, state }, completeConfig) {
      if (completeConfig) {
        try {
          await axios.post(
            `${process.env.VUE_APP_LARAVEL_API_URL}/restaurant/${state.restaurantId}/step`,
            {
              step: 'complete',
              value: completeConfig,
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`,
              },
            }
          );
        } catch (error) {
          console.error(`Erro ao salvar a conclusao da configuração:`, error);
        }
      }
      commit('setCompleteConfig', { completeConfig });
    },
    checkTokenValidity({ state, dispatch }) {
      const currentTimestamp = new Date().getTime();
      const tokenAge = currentTimestamp - state.tokenTimestamp;
      const tokenExpiry = 24 * 60 * 60 * 1000; // 24 horas

      if (tokenAge > tokenExpiry) {
        dispatch('removeToken');
      }
    },
    async fetchRestaurantId({ commit, dispatch, state }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
        commit('setRestaurantId', response.data.restaurant_id);
        return Promise.all([
          dispatch('fetchFormCategoriesAndComplements', response.data.restaurant_id),
          dispatch('fetchNavbarAndSidebarData', response.data.restaurant_id),
        ]);
      } catch (error) {
        console.error('Erro ao obter o restaurant_id:', error);
      }
    },
    async fetchNavbarAndSidebarData({ commit, dispatch, state }, restaurantId) {
      try {
        const id = restaurantId ? restaurantId : state.restaurantId;
        const response = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/restaurants/${id}/navbar-data`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
        commit('setNavbarAndSidebarData', response.data);
        dispatch('fetchMenusAndItems', response.data.restaurant_id);
      } catch (error) {
        console.error('Erro ao buscar navbar e sidebar data:', error);
      }
    },
    async fetchMenusAndItems({ commit, state }, restaurantId) {
      try {
        const id = restaurantId ? restaurantId : state.restaurantId;
        const response = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/menu-data?restaurant_id=${id}`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
        commit('setMenusAndItems', {
          menus: response.data.menus,
          items: response.data.items,
          crossSellItems: response.data.crossSellItems,
        });
        commit('setCategories', response.data.categories); // Armazena as categorias gerais no state
        const responseDetails = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/restaurants/${id}/detailed`, {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        });
        if (responseDetails.data) {
          const response = await axios.get(`${process.env.VUE_APP_LARAVEL_API_URL}/restaurant/${state.restaurantId}/steps`, {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          });
          if (response.data) {
            state.available = response.data.status;
          }
          if (response.data && (!response.data.complete || !response.data.status)) {
            state.sidebarData['links'] = {
              store: { complete: response.data.store },
              hours: { complete: response.data.hours },
              preparation: { complete: response.data.preparation },
              profile: { complete: response.data.profile },
              menu: { complete: response.data.menu },
              config: { complete: response.data.config },          
            };
          }
        }
      } catch (error) {
        console.error('Erro ao buscar menus e itens:', error);
      }
    },
    async fetchFormCategoriesAndComplements({ commit, state }, restaurantId) {
      try {
        const id = restaurantId ? restaurantId : state.restaurantId;
        // Requisição para buscar categorias para o formulário
        const responseCategories = await axios.get(
          `${process.env.VUE_APP_LARAVEL_API_URL}/category-products?restaurant_id=${id}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        );
        commit('setFormCategories', responseCategories.data.data); // Armazenando os dados de categorias do formulário

        // Requisição para buscar complementos para o formulário
        const responseComplements = await axios.get(
          `${process.env.VUE_APP_LARAVEL_API_URL}/components?restaurant_id=${id}`,
          { headers: { Authorization: `Bearer ${state.token}` } }
        );
        commit('setFormComplements', responseComplements.data.data); // Armazenando os dados de complementos do formulário
      } catch (error) {
        console.error('Erro ao buscar categorias e complementos para o formulário:', error);
      }
    },
    async preloadData({ dispatch }) {
      await dispatch('fetchRestaurantId');
    },
    resetStore({ commit }) {
      commit('clearToken');
      commit('setEmail', { email: null });
      commit('setCompleteConfig', { completeConfig: false });
      commit('setNavbarAndSidebarData', {});
      commit('setRestaurantId', null);
      commit('setMenusAndItems', { menus: [], items: {}, crossSellItems: [] });
      commit('setCategories', []);
      commit('setFormCategories', []);
      commit('setFormComplements', []);
      localStorage.clear();
    }
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer: (state) => ({
        token: state.token,
        tokenTimestamp: state.tokenTimestamp,
        email: state.email,
        completeConfig: state.completeConfig,
        navbarData: state.navbarData,
        sidebarData: state.sidebarData,
        restaurantId: state.restaurantId,
        menus: state.menus,
        items: state.items,
        crossSellItems: state.crossSellItems,
        categories: state.categories, // Persiste as categorias gerais
        formCategories: state.formCategories, // Persiste as categorias do formulário
        formComplements: state.formComplements, // Persiste os complementos do formulário
      }),
    }),
  ],
});

export default store;
