<template>
  <div class="privacy-policy container-data">
    <Navbar :noData="true" :loggedIn="0"/>
    <div class="content">
      <h1>Política de Exclusão de Conta dos Usuários – Prattu</h1>

      <p>A Prattu se preocupa com a privacidade e o controle dos seus dados. Caso queira excluir sua conta, siga as instruções abaixo:</p>
      <p><strong>Como solicitar a exclusão da conta</strong></p>
      <p>Para excluir sua conta na Prattu, siga estes passos:</p>

      <ol>
          <li><strong>Acesse o app Prattu</strong> e faça login na sua conta.</li>
          <li>Vá até <strong>Perfil > Dados do Perfil > Deletar conta.</strong></li>
          <li>Confirme a solicitação digitando sua senha.</li>
          <li>Sua conta será removida permanentemente após a confirmação.</li>
      </ol>

      <p>Se tiver dificuldades, você também pode solicitar a exclusão entrando em contato com nosso suporte pelo e-mail <strong>suporte@prattuapp.com.br</strong></p>

      <p><strong>O que acontece com seus dados?</strong></p>

      <ul>
          <li><strong>Dados excluídos: </strong>Todas as suas informações pessoais, incluindo nome, e-mail, telefone, preferências e histórico de pedidos, serão permanentemente removidas dos nossos sistemas.</li>
          <li><strong>Dados mantidos: </strong>Para cumprir obrigações legais e regulatórias, algumas informações podem ser retidas por até 6 meses, como registros de transações e interações de suporte.</li>
          <li><strong>Exclusão completa: </strong>Após o período necessário para cumprimento de requisitos legais, todos os seus dados serão eliminados de forma definitiva.</li>
      </ul>

      <p>Se tiver dúvidas, entre em contato com nosso suporte.</p>

      <p>Última atualização: 10/03/2025</p>

    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";

export default {
  name: "LGPD",
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.container-data {
  padding-top: 70px;
  max-width: 100%;
}

.content {
  max-width: 1280px; /* Define a largura máxima do conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo */
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.content h1, .content h2, .content h3 {
  margin-top: 20px;
  font-weight: bold;
}

.content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.content a {
  color: #007bff;
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}
</style>
